var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"centivo plan-affiliations-form-wrapper"},[_c('div',{staticClass:"plan-affiliations-form"},[_vm._m(0),(_vm.selectedClient || !_vm.clientData?.planAffiliations)?_c('div',{staticClass:"form-area"},[_c('form',[_c('DropDownComponent',{attrs:{"name":"clientId","label":"Client ID","options":_vm.clients},on:{"update:selectedOption":_vm.onChangeHandler},model:{value:(_vm.selectedClient),callback:function ($$v) {_vm.selectedClient=$$v},expression:"selectedClient"}})],1)]):_vm._e(),(_vm.selectedClient && _vm.selectedClient.title !== 'NONE')?_c('div',[_vm._m(1),_c('div',{staticClass:"table-wrapper"},[_vm._m(2),(_vm.selectedClient)?_c('div',{staticClass:"table-content"},_vm._l((_vm.selectedClient.plans),function(item){return _c('div',{key:item.planId,staticClass:"plans-list"},[_c('div',{staticClass:"plan-id"},[_vm._v(_vm._s(item.planId))]),_c('div',{staticClass:"dd-content"},[_c('form',[_c('DropDownComponent',{attrs:{"name":item.planId,"options":_vm.networkNames.map((nn) => {
                      return {
                        ...nn,
                        isSelected:
                          item.networkAffiliation &&
                          item.networkAffiliation.networkName &&
                          item.networkAffiliation.uuid === nn.value,
                      };
                    })},on:{"update:selectedOption":_vm.onChangeNetworkNameHandler}})],1)])])}),0):_vm._e()])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"client-text-info"},[_c('p',[_vm._v(" Start by selecting the client ID to affiliate the configurations to the plan ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"plan-text-info"},[_c('p',[_vm._v("Select a network name to affiliate to each plan ID")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-header"},[_c('p',[_vm._v("PLAN ID")]),_c('p',[_vm._v("NETWORK NAME")])])
}]

export { render, staticRenderFns }